import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { apiCall } from './../../../helpers/api';
import { Container, Row, Carousel, ProgressBar, FloatingLabel, Form, ToggleButton, ToggleButtonGroup, Alert } from 'react-bootstrap';
import { getAge } from "../../../helpers/dates";
import { getCountries } from "../../../helpers/dropdowns";
import AlertModal from "../../../components/alerts/alert";
import './Signup.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import blueTheRoomLogo from './../../../images/blueTheRoomLogo.svg';
import adBox1 from './../../../images/adBox1.png';
import adBox2 from './../../../images/adBox2.png';
import adBox3 from './../../../images/adBox3.png';
import instagram from './../instagram.svg';
import tiktok from './../tiktok.svg';
import youtube from './../youtube.svg';
import facebook from './../facebook.svg';
import twitter from './../twitter.svg';
import plus from './../plus.svg';
import minus from './../minus.svg';
import cancel from './../cancel.svg';
import SignupFooter from "../../../components/footer/SignupFooter";
import { formatText } from "../../../helpers/format";
import { parameters } from './../../../helpers/globalSettings'
import ReCAPTCHA from "react-google-recaptcha";

import theRoomIcon from './../../../images/theRoom-icon.svg';

export default function InfluencerSignup() {
    const countries = getCountries();
    const [signupData, setSignupData] = useState({
        "terms" : false,
        "socials": [],
        "primaryInterest": "",
        "otherInterests": [],
        "influencerName": "",
        "genderSpecify": "",
        "gender": "Male",
        "referral": "",
    });
    const [isAgent, setIsAgent] = useState(false);
    const [isReferral, setIsReferral] = useState(false);
    const [specifyGender, setSpecifyGender] = useState(false);
    const [instaLink, setInstaLink] = useState(false);
    const [tiktokLink, setTiktokLink] = useState(false);
    const [youtubeLink, setYoutubeLink] = useState(false);
    const [facebookLink, setFacebookLink] = useState(false);
    const [twitterLink, setTwitterLink] = useState(false);
    const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
    const [HandleAlert, setHandleAlert] = useState({"state": false, "title": "", "message": ""});
    const [captchaToken, setCaptchaToken] = useState()

    const [applicationStep, setApplicationStep] = useState(0)

    function ToggleButtonGroupPrimaryCategories() {     
        return (
        <ToggleButtonGroup type="checkbox" value={signupData.primaryInterest} onChange={(choice) => updateSignupDataState([choice[1]],"primaryInterest",true)}>
                <ToggleButton id="tbg-btn-1" className="me-2 mb-2" value="Activities">Activities</ToggleButton>
                <ToggleButton id="tbg-btn-2" className="me-2 mb-2" value="Arts & Culture">Arts & Culture</ToggleButton>
                <ToggleButton id="tbg-btn-3" className="me-2 mb-2" value="Automotive">Automotive</ToggleButton>
                <ToggleButton id="tbg-btn-4" className="me-2 mb-2" value="Beauty, Grooming & Fragrance">Beauty, Grooming & Fragrance</ToggleButton>
                <ToggleButton id="tbg-btn-5" className="me-2 mb-2" value="Books & Magazines">Books & Magazines</ToggleButton>
                <ToggleButton id="tbg-btn-6" className="me-2 mb-2" value="Charity & Nonprofit">Charity & Nonprofit</ToggleButton>
                <ToggleButton id="tbg-btn-7" className="me-2 mb-2" value="Children & Baby">Children & Baby</ToggleButton>
                <ToggleButton id="tbg-btn-8" className="me-2 mb-2" value="Entertainment & Media">Entertainment & Media</ToggleButton>
                <ToggleButton id="tbg-btn-9" className="me-2 mb-2" value="Environment">Environment</ToggleButton>
                <ToggleButton id="tbg-btn-10" className="me-2 mb-2" value="Fashion">Fashion</ToggleButton>
                <ToggleButton id="tbg-btn-11" className="me-2 mb-2" value="Food & Drink">Food & Drink</ToggleButton>
                <ToggleButton id="tbg-btn-12" className="me-2 mb-2" value="Health, Wellness & Personal Care">Health, Wellness & Personal Care</ToggleButton>
                <ToggleButton id="tbg-btn-13" className="me-2 mb-2" value="Holidays & Travel">Holidays & Travel</ToggleButton>
                <ToggleButton id="tbg-btn-14" className="me-2 mb-2" value="Home & Garden">Home & Garden</ToggleButton>
                <ToggleButton id="tbg-btn-15" className="me-2 mb-2" value="Lifestyle">Lifestyle</ToggleButton>
                <ToggleButton id="tbg-btn-16" className="me-2 mb-2" value="Pets">Pets</ToggleButton>
                <ToggleButton id="tbg-btn-17" className="me-2 mb-2" value="Restaurants, Bars & Clubs">Restaurants, Bars & Clubs</ToggleButton>
                <ToggleButton id="tbg-btn-18" className="me-2 mb-2" value="Sport & Fitness">Sport & Fitness</ToggleButton>
                <ToggleButton id="tbg-btn-19" className="me-2 mb-2" value="Technology, Gaming & Electronics">Technology, Gaming & Electronics</ToggleButton>
                <ToggleButton id="tbg-btn-20" className="me-2 mb-2" value="Tickets">Tickets</ToggleButton>
        </ToggleButtonGroup>
        );
    }

    function ToggleButtonGroupOtherCategories() {     
        return (
            <ToggleButtonGroup type="checkbox" value={signupData.otherInterests} onChange={(choice) => updateSignupDataState(choice,"otherInterests",true)}>
                    <ToggleButton id="tbg-btn-21" className="me-2 mb-2" value="Activities">Activities</ToggleButton>
                    <ToggleButton id="tbg-btn-22" className="me-2 mb-2" value="Arts & Culture">Arts & Culture</ToggleButton>
                    <ToggleButton id="tbg-btn-23" className="me-2 mb-2" value="Automotive">Automotive</ToggleButton>
                    <ToggleButton id="tbg-btn-24" className="me-2 mb-2" value="Beauty, Grooming & Fragrance">Beauty, Grooming & Fragrance</ToggleButton>
                    <ToggleButton id="tbg-btn-25" className="me-2 mb-2" value="Books & Magazines">Books & Magazines</ToggleButton>
                    <ToggleButton id="tbg-btn-26" className="me-2 mb-2" value="Charity & Nonprofit">Charity & Nonprofit</ToggleButton>
                    <ToggleButton id="tbg-btn-27" className="me-2 mb-2" value="Children & Baby">Children & Baby</ToggleButton>
                    <ToggleButton id="tbg-btn-28" className="me-2 mb-2" value="Entertainment & Media">Entertainment & Media</ToggleButton>
                    <ToggleButton id="tbg-btn-29" className="me-2 mb-2" value="Environment">Environment</ToggleButton>
                    <ToggleButton id="tbg-btn-30" className="me-2 mb-2" value="Fashion">Fashion</ToggleButton>
                    <ToggleButton id="tbg-btn-31" className="me-2 mb-2" value="Food & Drink">Food & Drink</ToggleButton>
                    <ToggleButton id="tbg-btn-32" className="me-2 mb-2" value="Health, Wellness & Personal Care">Health, Wellness & Personal Care</ToggleButton>
                    <ToggleButton id="tbg-btn-33" className="me-2 mb-2" value="Holidays & Travel">Holidays & Travel</ToggleButton>
                    <ToggleButton id="tbg-btn-34" className="me-2 mb-2" value="Home & Garden">Home & Garden</ToggleButton>
                    <ToggleButton id="tbg-btn-35" className="me-2 mb-2" value="Lifestyle">Lifestyle</ToggleButton>
                    <ToggleButton id="tbg-btn-36" className="me-2 mb-2" value="Pets">Pets</ToggleButton>
                    <ToggleButton id="tbg-btn-37" className="me-2 mb-2" value="Restaurants, Bars & Clubs">Restaurants, Bars & Clubs</ToggleButton>
                    <ToggleButton id="tbg-btn-38" className="me-2 mb-2" value="Sport & Fitness">Sport & Fitness</ToggleButton>
                    <ToggleButton id="tbg-btn-39" className="me-2 mb-2" value="Technology, Gaming & Electronics">Technology, Gaming & Electronics</ToggleButton>
                    <ToggleButton id="tbg-btn-40" className="me-2 mb-2" value="Tickets">Tickets</ToggleButton>
            </ToggleButtonGroup>
        );
    }

    const animatedComponents = makeAnimated();

    const options = [
        { value: 'Activities', label: 'Activities' },
        { value: 'Arts & Culture', label: 'Arts & Culture' },
        { value: 'Automotive', label: 'Automotive' },
        { value: 'Beauty, Grooming & Fragrance', label: 'Beauty, Grooming & Fragrance' },
        { value: 'Books & Magazines', label: 'Books & Magazines' },
        { value: 'Charity & Nonprofit', label: 'Charity & Nonprofit' },
        { value: 'Children & Baby', label: 'Children & Baby' },
        { value: 'Entertainment & Media', label: 'Entertainment & Media' },
        { value: 'Environment', label: 'Environment' },
        { value: 'Fashion', label: 'Fashion' },
        { value: 'Food & Drink', label: 'Food & Drink' },
        { value: 'Health, Wellness & Personal Care', label: 'Health, Wellness & Personal Care' },
        { value: 'Holidays & Travel', label: 'Holidays & Travel' },
        { value: 'Home & Garden', label: 'Home & Garden' },
        { value: 'Lifestyle', label: 'Lifestyle' },
        { value: 'Pets', label: 'Pets' },
        { value: 'Restaurants, Bars & Clubs', label: 'Restaurants, Bars & Clubs' },
        { value: 'Sport & Fitness', label: 'Sport & Fitness' },
        { value: 'Technology, Gaming & Electronics', label: 'Technology, Gaming & Electronics' },
        { value: 'Tickets', label: 'Tickets' }
    ]

    const requiredFields = ["firstName","lastName","primaryInterest","email","dob","terms","socials","country"];

    async function submit(event) {
        event.preventDefault();
        // Validate data
        var valid = true;
        var validationIssues = [];
        requiredFields.forEach(field => {
            if (!(field in signupData)){
                validationIssues.push(`${field} is missing`)
                valid = false;
            }
            else if (signupData[field].length == 0){
                validationIssues.push(`${field} is empty`)
                valid = false;
            }
        });
        if (isAgent && signupData["influencerName"].length == 0){
            validationIssues.push("influencer name not defined")
            valid = false;
        }
        if (specifyGender && signupData["gender"].length == 0){
            validationIssues.push("gender not defined")
            valid = false;
        }
        if (isReferral && (!signupData["referral"] || signupData["referral"].length == 0)){
            validationIssues.push("referrer was not defined")
            valid = false;
        }
        if (!signupData["terms"]){
            validationIssues.push("terms have not been accepted")
            valid = false;
        }
        if (getAge(signupData["dob"]) < 18){
            validationIssues.push("you must be at least 18 years of age to apply")
            valid = false;
        }
        if (getAge(signupData["dob"]) > 130){
            validationIssues.push("invalid age provided")
            valid = false;
        }
        if ([...new Set(signupData["otherInterests"])].filter(function(interest) { return interest != signupData["primaryInterest"] }).length == 0) {
            validationIssues.push("you must choose at least one different interest to your primary interest")
            valid = false;
        }
        if (valid){
            var tempSignupData = Object.assign({},signupData);
            tempSignupData["captchaToken"] = captchaToken;
            if (!isReferral) { tempSignupData["referral"] = ""}
            tempSignupData["otherInterests"] = [...new Set(tempSignupData["otherInterests"])].filter(function(interest) { return interest != tempSignupData["primaryInterest"] }); // Make sure it's not already in the interests
            tempSignupData["otherInterests"].unshift(tempSignupData["primaryInterest"][0])
            tempSignupData["interests"] = tempSignupData["otherInterests"]
            if (!isAgent){
                tempSignupData["influencerName"] = tempSignupData["firstName"] + " " + tempSignupData["lastName"]
            }
            var response = await apiCall('application?type=influencer',{ "method" : "POST", "data" : tempSignupData });
            if (response["success"]){
                setAlert({"display":"centered","visible":true,"title":"Application Submitted!","content":`Your application has been submitted, we'll be in touch! (You will be redirected to the login page in 10 seconds)`,"className":"bg-success text-white"})
                var timeLeft = 9;
                var redirectTimer = setInterval(function(){
                    if(timeLeft <= 0){
                        clearInterval(redirectTimer);
                        window.location.href="/Login";
                    }
                    setAlert({"display":"centered","visible":true,"title":"Application Submitted!","content":`Your application has been submitted, we'll be in touch! (You will be redirected to the login page in ${timeLeft} seconds)`,"className":"bg-success text-white"})
                    timeLeft -= 1;
                }, 1000);
            }
            else if (response["failure"]){
                if (response["failure"]["type"] === "existing social") {
                    setHandleAlert({"state": true, "title": "Have you already signed up?", "message": formatText(`${response["failure"]["error"]}.\n
                    Please click <a href="/Login">here</a> to login or contact us by email at support@theinfluenceroom.com\n`)})
                }
                else {
                    setAlert({"display":"toast","visible":true,"title":"Application Failed to Submit","content":"There was an issue with your application. Please double check your submission and try again. Error: "+response["failure"]["error"],"className":"bg-danger text-white"})
                }
            }
        }
        else {
            setAlert({"display":"toast","visible":true,"title":"Application Invalid","content":`Your application is invalid due to the following reasons: ${validationIssues.join(", ")}`,"className":"bg-danger text-white"})
        }
    }

    function removeSocial(name){
        var tempUserData = Object.assign({},signupData);
        var newSocial = [];
        tempUserData["socials"].forEach(social => {
            if (social["name"] != name){
                newSocial.push(social)
            }
        })
        tempUserData["socials"] = newSocial;
        document.getElementById("social_"+name).value="";
        setSignupData(tempUserData); 
    }

    function updateSignupDataState(props, id=null, mobile=false) {
        var tempUserData = Object.assign({},signupData);
        if (id != null){
            if (mobile) {
                tempUserData[id] = props;
            }
            else {
                tempUserData[id] = props.map(item => item.value);
            }
            if (id == "otherInterests" && tempUserData[id].includes(signupData["primaryInterest"])) {
                tempUserData[id] = tempUserData[id].filter(function(interest) { return interest != signupData["primaryInterest"] })
            }
        }
        else if ( props.target.type == "checkbox" ) {
            tempUserData[props.target.id] = props.target.checked;
        } 
        else {
            if (props.target.id == "genderSpecify"){
                tempUserData["gender"] = props.target.value;
            }
            else if (props.target.id == "gender") {
                if (props.target.value == "Prefer to self-describe (please specify)") {
                    setSpecifyGender(true)
                }
                else {
                    setSpecifyGender(false)
                }
                tempUserData["gender"] = props.target.value
            }
            else if (props.target.id.includes("social_")){
                var newSocial = [];
                var found = false;
                tempUserData["socials"].forEach(social => {
                    if (social["name"] == props.target.id.split("social_")[1]){
                        newSocial.push({"name":social["name"],"handle":props.target.value})
                        found = true;
                    }
                    else {
                        newSocial.push(social)
                    }
                })
                if (!found){
                    newSocial.push({"name":props.target.id.split("social_")[1],"handle":props.target.value})
                }
                tempUserData["socials"] = newSocial;
            }
            else {
                tempUserData[props.target.id] = props.target.value;
            }
        }
        setSignupData(tempUserData); 
    }       
 
    return (
        <div>
            <AlertModal modal={showAlert} onClose={() => setAlert({"display":"toast","visible":false,"title":"","content":""})} />
            <Container fluid className="signupBody desktopView influencerRegistration">
                <Row className='h-100 signupRight'>
                    <div className='col-lg-5 position-relative h-100 signupSummaryText'>
                        <img src={blueTheRoomLogo} height="40px" className='mt-4 mt-lg-5 ms-md-5 ms-4 mb-5' />
                        <div className="p-xl-5 ps-xl-0 p-3 p-md-0 mx-md-5 mx-3 position-overlay">
                            <h3 className="text-navy mb-4 position-overlay fw-700">Look inside The Influence Room</h3>
                            <p>The Influence Room is a private space where people of influence connect with the brands they need, love or want.</p>
                            <p className="fw-500 mb-1">As a member, you'll benefit from:</p>
                            <ul className="ps-4">
                                <li>Exclusive access to brands, products and events</li>
                                <li>Creative freedom to make authentic content</li>
                                <li>Rewards for being true to yourself</li>
                            </ul>
                            <p>Join us now for the opportunity to work with amazing brands that you know and love.</p>
                            <div className="row mt-5">
                                <div className="col-12 col-md-4 mb-5">
                                    <img src={adBox1} className="w-100" />
                                </div>
                                <div className="col-12 col-md-4 mb-5">
                                    <img src={adBox2} className="w-100" />
                                </div>
                                <div className="col-12 col-md-4 mb-5">
                                    <img src={adBox3} className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 bg-white p-5 p-lg-4 p-xl-5 pb-0">    
                        {HandleAlert.state &&
                            <Alert variant="warning" onClose={() => setHandleAlert({"state": false, "title": "", "message": ""})} className="handleAlert start-50 top-0 translate-middle-x w-75 mt-4" transition dismissible>
                                <Alert.Heading>{HandleAlert.title}</Alert.Heading>
                                {HandleAlert.message}
                            </Alert>
                        }  
                        <form className="m-md-5 m-0 me-lg-0 mx-xl-5 ms-lg-3 my-md-4">
                            <h5 className="mb-4 text-navy">Influencer Registration</h5>
                            {/* <Row>
                            <div className='form-group mb-3'>
                                <label className="small"><input type="checkbox" className="form-check-input" id="terms" onChange={() => setIsAgent((isAgent) => !isAgent)} /> &nbsp;I'm applying on behalf of someone else</label>
                            </div>
                            
                                {isAgent && (
                                <div className="col-sm-12 col-md-6 mb-3">
                                    <div className="form-group">
                                        <label>Influencer's Full Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" id="influencerName" onChange={ updateSignupDataState } />
                                    </div>
                                </div>
                                )}
                            </Row> */}
                            <Row>
                                <div className="col-sm-12 col-md-6 mb-3">
                                    <div className="form-group">
                                        <label>{isAgent && ("Your")} First Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" id="firstName" onChange={ updateSignupDataState } />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6 mb-3">
                                    <div className="form-group">
                                        <label>{isAgent && ("Your")} Last Name <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" id="lastName" onChange={ updateSignupDataState } />
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-sm-12 col-md-6 mb-3">
                                    <div className='form-group'>
                                        <label>Email Address <span className="text-danger">*</span></label>
                                        <input type="email" className="form-control" id="email" onChange={ updateSignupDataState } />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-2 mb-3">
                                    <div className="form-group">
                                        <label className="text-nowrap">Gender <span className="text-danger">*</span></label>
                                        <select type="text" className="form-control" id="gender" onChange={ updateSignupDataState }>
                                            <option>Male</option>
                                            <option>Female</option>
                                            <option>Prefer not to say</option>
                                            <option>Prefer to self-describe (please specify)</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-4 mb-3">
                                    <div className='form-group'>
                                        <label>Date of Birth <span className="text-danger">*</span></label>
                                        <input type="date" className="form-control" data-date-inline-picker="true" id="dob" onChange={ updateSignupDataState } />
                                    </div>
                                </div>

                                {specifyGender && (
                                <div className="col-sm-12 col-md-6 mb-3">
                                    <div className='form-group'>
                                        <label>Please write your gender <span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" id="genderSpecify" onChange={ updateSignupDataState } />
                                    </div>
                                </div>
                                )}
                            </Row>
                            <Row>
                                <div className="col-sm-12 col-md-6 mb-3">
                                <div className='form-group'>
                                        <label>Primary Interest <span className="text-danger">*</span></label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            components={animatedComponents}
                                            defaultValue={[]}
                                            options={options}
                                            id="primaryInterest" 
                                            onChange={ (choice) => updateSignupDataState([choice],"primaryInterest") } 
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 mb-3">
                                    <div className='form-group'>
                                        <label>Interests <span className="text-danger">*</span></label>
                                        <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            defaultValue={[]}
                                            isMulti
                                            options={options}
                                            id="otherInterests" 
                                            onChange={ (choice) => updateSignupDataState(choice,"otherInterests") } 
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6 mb-3">
                                    <div className='form-group'>
                                        <label>Country <span className="text-danger">*</span></label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            components={animatedComponents}
                                            defaultValue={[]}
                                            options={countries} 
                                            id="country" 
                                            onChange={ (choice) => updateSignupDataState({"target":{"id":"country","value":choice.value}}) } 
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-12">
                                    <div className='form-group'>

                                        <label>Social Media <span className="text-danger">*</span></label>
                                        <button type="button" className="btn btn-sm btn-light socialMedia me-2 mb-2 border" id="instaLink" onClick={()=>{!instaLink && removeSocial("instagram"); setInstaLink((instaLink) => !instaLink)}}><img src={instagram} width="24px" /> <img src={`${!instaLink ? plus: minus}`} width="20px" className="ms-1" /></button>

                                        <button type="button" className="btn btn-sm btn-light socialMedia me-2 mb-2 border" id="tiktokLink" onClick={()=>{!tiktokLink && removeSocial("tiktok"); setTiktokLink((tiktokLink) => !tiktokLink)}}><img src={tiktok} width="24px" /> <img src={`${!tiktokLink ? plus: minus}`} width="20px" className="ms-1" /></button>

                                        <button type="button" className="btn btn-sm btn-light socialMedia me-2 mb-2 border" id="youtubeLink" onClick={()=>{!youtubeLink && removeSocial("youtube"); setYoutubeLink((youtubeLink) => !youtubeLink)}}><img src={youtube} width="24px" /> <img src={`${!youtubeLink ? plus: minus}`} width="20px" className="ms-1" /></button>

                                        <button type="button" className="btn btn-sm btn-light socialMedia me-2 mb-2 border" id="facebookLink" onClick={()=>{!facebookLink && removeSocial("facebook"); setFacebookLink((facebookLink) => !facebookLink)}}><img src={facebook} width="24px" /> <img src={`${!facebookLink ? plus: minus}`} width="20px" className="ms-1" /></button>

                                        <button type="button" className="btn btn-sm btn-light socialMedia me-2 mb-2 border" id="twitterLink" onClick={()=>{!twitterLink && removeSocial("twitter"); setTwitterLink((twitterLink) => !twitterLink)}}><img src={twitter} width="24px" /> <img src={`${!twitterLink ? plus: minus}`} width="20px" className="ms-1" /></button>
                                        
                                        <div className="row mt-3">
                                            <div className={`col-sm-12 col-md-6 col-lg-4 mb-3 ${!instaLink ? "hide": ""}`}>
                                                <div className="input-group">
                                                    <span className="input-group-text py-0 px-2 bg-gray"><img src={instagram} width="24px" /></span>
                                                    <span className="input-group-text fw-bold py-0 ps-2 pe-1 bg-gray">@</span>
                                                    <input type="url" className="form-control border-start-0" id="social_instagram" onChange={ updateSignupDataState } placeholder="Instagram Handle" />
                                                    <button className="btn btn-danger py-0 pb-1 px-1" type="button" id="deleteInstagram" onClick={()=>{removeSocial("instagram"); setInstaLink(false)}}><img src={cancel} width="20px" /></button>
                                                </div>
                                            </div>
                                            <div className={`col-sm-12 col-md-6 col-lg-4 mb-3 ${!tiktokLink ? "hide": ""}`}>
                                                <div className="input-group">
                                                    <span className="input-group-text py-0 px-2 bg-gray"><img src={tiktok} width="24px" /></span>
                                                    <span className="input-group-text fw-bold py-0 ps-2 pe-1 bg-gray">@</span>
                                                    <input type="text" className="form-control border-start-0" id="social_tiktok" onChange={ updateSignupDataState } placeholder="TikTok Handle" />
                                                    <button className="btn btn-danger py-0 pb-1 px-1" type="button" id="deleteTiktok" onClick={()=>{removeSocial("tiktok"); setTiktokLink(false)}}><img src={cancel} width="20px" /></button>
                                                </div>
                                            </div>
                                            <div className={`col-sm-12 col-md-6 col-lg-4 mb-3 ${!youtubeLink ? "hide": ""}`}>
                                                <div className="input-group">
                                                    <span className="input-group-text py-0 px-2 bg-gray"><img src={youtube} width="24px" /></span>
                                                    <input type="text" className="form-control border-start-0" id="social_youtube" onChange={ updateSignupDataState } placeholder="Youtube Channel ID" />
                                                    <button className="btn btn-danger py-0 pb-1 px-1" type="button" id="deleteYoutube" onClick={()=>{removeSocial("youtube"); setYoutubeLink(false)}}><img src={cancel} width="20px" /></button>
                                                </div>
                                            </div>
                                            <div className={`col-sm-12 col-md-6 col-lg-4 mb-3 ${!facebookLink ? "hide": ""}`}>
                                                <div className="input-group">
                                                    <span className="input-group-text py-0 px-2 bg-gray"><img src={facebook} width="24px" /></span>
                                                    <input type="text" className="form-control border-start-0" id="social_facebook" onChange={ updateSignupDataState } placeholder="Facebook Handle" />
                                                    <button className="btn btn-danger py-0 pb-1 px-1" type="button" id="deleteFacebook" onClick={()=>{removeSocial("facebook"); setFacebookLink(false)}}><img src={cancel} width="20px" /></button>
                                                </div>
                                            </div>
                                            <div className={`col-sm-12 col-md-6 col-lg-4 mb-3 ${!twitterLink ? "hide": ""}`}>
                                                <div className="input-group">
                                                    <span className="input-group-text py-0 px-2 bg-gray"><img src={twitter} width="24px" /></span>
                                                    <span className="input-group-text fw-bold py-0 ps-2 pe-1 bg-gray">@</span>
                                                    <input type="text" className="form-control border-start-0" id="social_twitter" onChange={ updateSignupDataState } placeholder="Twitter Handle" />
                                                    <button className="btn btn-danger py-0 pb-1 px-1" type="button" id="deleteTwitter" onClick={()=>{removeSocial("twitter"); setTwitterLink(false)}}><img src={cancel} width="20px" /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Row>                            
                            <Row>
                            <div className='form-group mb-3'>
                                <label className="small"><input type="checkbox" value={isReferral} className="form-check-input" id="referralConfirm" onChange={() => setIsReferral((isReferral) => !isReferral)} /> &nbsp;I was referred by someone</label>
                            </div>
                            
                                {isReferral && (
                                <div className="col-sm-12 col-md-6 mb-3">
                                    <div className="form-group">
                                        <label>Their referral code</label>
                                        <input type="text" className="form-control" id="referral" onChange={ updateSignupDataState } />
                                    </div>
                                </div>
                                )}
                            </Row>
                            <Row>
                                <div className="col-sm-12 col-md-12">
                                    <div className='form-group mb-3'>
                                        <label className="small"><input type="checkbox" className="form-check-input" id="terms" onChange={ updateSignupDataState } /> &nbsp;I have read and agree to the <a href="https://www.theinfluenceroom.com/terms-of-use/" target='_blank'>Terms and Conditions</a> and <a href="https://www.theinfluenceroom.com/privacy-policy/" target='_blank'>Privacy Policy</a> <span className="text-danger">*</span></label>
                                    </div>

                                    <ReCAPTCHA
                                        sitekey={parameters.recaptcha}
                                        onChange={(token) => {setCaptchaToken(token)}}
                                    />

                                    <button type='submit' className="link btn btn-primary" disabled={!captchaToken} onClick={submit}>Get Started</button>
                                </div>
                            </Row>
                            
                            <div className='mt-4 mb-5'>
                                <p>Want to change your mind? <Link to={"/Signup"} title="Signup">Go Back!</Link></p>
                            </div>
                        </form>
                    </div>
                </Row>
            </Container>
      
            <SignupFooter />

            <Container className="pt-2 px-0 signupInfluencer mobileView">
                <div className='w-100 text-center position-relative mt-4'>
                    <img src={theRoomIcon} />
                </div>
                <Carousel>
                    <Carousel.Item>
                        <img className="d-block w-100" src={"https://res.cloudinary.com/theroomio/image/upload/c_scale,w_500/platform/signup-carousel-1_ulrnda"} alt="First slide" />
                        <Carousel.Caption>
                            <h4 className="text-navy fw-bold">Access top brands. Have amazing experiences. Tell real stories</h4>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={"https://res.cloudinary.com/theroomio/image/upload/c_scale,w_500/platform/signup-carousel-2_pcmv38"} alt="First slide" />
                        <Carousel.Caption>
                            <h4 className="text-navy fw-bold px-4">Connect instantly with brands you love</h4>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" src={"https://res.cloudinary.com/theroomio/image/upload/c_scale,w_500/platform/signup-carousel-3_ag1qsk"} alt="First slide" />
                        <Carousel.Caption>
                            <h4 className="text-navy fw-bold px-4">Produce authentic content with creative freedom</h4>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
                <Row className="position-absolute bottom-0 w-100 mx-0 pb-3">
                    <div className="col-6">
                        <button type="button" onClick={()=>setApplicationStep(1)} className="btn btn-primary w-100">Apply</button>
                    </div>
                    <div className="col-6">
                        <Link to={"/Signup"} title="Signup" className="btn btn-outline-primary w-100">Back to Signup</Link>
                    </div>
                </Row>
            </Container>

            <Container className={`influencerStep ${applicationStep != 1 ? "hide" : ""}`}>
                <div className="px-3 py-4 influencerStepBody">
                    <div className='w-100 text-center position-relative pb-2 mb-4'>
                        <img src={theRoomIcon} />
                    </div>
                    <div className="mt-4">
                        <p>The Influence Room is a private space where people of influence connect with the brands they need, love or want.</p>
                        <p className="fw-bold">As a member, you'll benefit from:</p>
                        <ul>
                            <li>Exclusive access to brands, products and events</li>
                            <li>Creative freedom to make authentic content</li>
                            <li>Rewards for being true to yourself</li>
                        </ul>
                        <p>Join us now for the opportunity to work with amazing brands that you know and love.</p>
                    </div>
                </div>
                <div className="position-fixed bottom-0 w-100 bg-secondary p-3 d-flex justify-content-between align-items-center">
                    <button type="button" onClick={()=>setApplicationStep(0)} className="btn btn-sm btn-outline-primary">Back</button>
                    <button type="button" onClick={()=>setApplicationStep(2)} className="btn btn-sm btn-primary">Continue</button>
                </div>                
            </Container>

            <Container className={`influencerStep ${applicationStep != 2 ? "hide" : ""}`}>
                <div className="px-3 py-4 influencerStepBody">
                    <div className='w-100 text-center position-relative pb-2 mb-4'>
                        <img src={theRoomIcon} />
                    </div>
                    <ProgressBar className="progressInfluencer" animated now={10} />
                    <div className="text-center mt-4 h5 fw-bold">What's your name?</div>
                    <div className="px-3 mt-4">
                        <FloatingLabel label="First Name">
                            <Form.Control className="border-0 rounded-0 border-bottom" id="firstName" type="text" placeholder="John" onChange={ updateSignupDataState } />
                        </FloatingLabel>
                        <FloatingLabel label="Last Name">
                            <Form.Control className="border-0 rounded-0 border-bottom" id="lastName" type="text" placeholder="Smith" onChange={ updateSignupDataState } />
                        </FloatingLabel>
                        <div className="px-3 text-center mt-4 small">
                            <p>Your name will be displayed on your profile and as part of bids you make on campaigns.</p>
                            <p>You should use your full real name, not a username. </p>
                        </div>
                    </div>
                </div>
                <div className="position-fixed bottom-0 w-100 bg-secondary p-3 d-flex justify-content-between align-items-center">
                    <button type="button" onClick={()=>setApplicationStep(1)} className="btn btn-sm btn-outline-primary">Back</button>
                    <button type="button" onClick={()=>setApplicationStep(3)} className="btn btn-sm btn-primary">Continue</button>
                </div>                
            </Container>

            <Container className={`influencerStep ${applicationStep != 3 ? "hide" : ""}`}>
                <div className="px-3 py-4 influencerStepBody">
                    <div className='w-100 text-center position-relative pb-2 mb-4'>
                        <img src={theRoomIcon} />
                    </div>
                    <ProgressBar className="progressInfluencer" animated now={20} />
                    <div className="text-center mt-4 h5 fw-bold">What's your email?</div>
                    <div className="px-3 mt-4">
                        <FloatingLabel label="Email">
                            <Form.Control className="border-0 rounded-0 border-bottom" type="email" id="email" placeholder="Email" onChange={(email) => updateSignupDataState(email) } />
                        </FloatingLabel>
                        <div className="px-3 text-center mt-4 small">
                            <p>You will be required to verify your email address in order to complete your account set up.</p>
                            <p>You may be contacted by email in regards to account status updates, notifications and delivery updates.</p>
                            <p>If your application is approved, you can change your notification preferences or unsubscribe at any point within the app.</p>
                        </div>
                    </div>
                </div>
                <div className="position-fixed bottom-0 w-100 bg-secondary p-3 d-flex justify-content-between align-items-center">
                    <button type="button" onClick={()=>setApplicationStep(2)} className="btn btn-sm btn-outline-primary">Back</button>
                    <button type="button" onClick={()=>setApplicationStep(4)} className="btn btn-sm btn-primary">Continue</button>
                </div>                
            </Container>

            <Container className={`influencerStep ${applicationStep != 4 ? "hide" : ""}`}>
                <div className="px-3 py-4 influencerStepBody">
                    <div className='w-100 text-center position-relative pb-2 mb-4'>
                        <img src={theRoomIcon} />
                    </div>
                    <ProgressBar className="progressInfluencer" animated now={30} />
                    <div className="text-center mt-4 h5 fw-bold">What's your gender?</div>
                    <div className="px-3 mt-4 pt-3">
                        <div className="border rounded p-2 mb-3">
                            <Form.Check className="ms-1" type="radio" checked={signupData.gender == "Male" ? true : false} onChange={()=>updateSignupDataState({"target":{"id":"gender","value":"Male"}})} id="genderMale" name="genderCheck" label="Male" /> 
                        </div>   
                        <div className="border rounded p-2 mb-3">
                            <Form.Check className="ms-1" type="radio" checked={signupData.gender == "Female" ? true : false} onChange={()=>updateSignupDataState({"target":{"id":"gender","value":"Female"}})} id="genderFemale" name="genderCheck" label="Female" />    
                        </div>
                        <div className="border rounded p-2 mb-3">
                            <Form.Check className="ms-1" type="radio" checked={signupData.gender == "Prefer not to say" ? true : false} onChange={()=>updateSignupDataState({"target":{"id":"gender","value":"Prefer not to say"}})} id="genderNA" name="genderCheck" label="Prefer not to say" />    
                        </div>
                        <div className="border rounded p-2 mb-3">
                            <Form.Check className="ms-1" type="radio" checked={signupData.gender == "Prefer to self-describe (please specify)" ? true : false} onChange={()=>updateSignupDataState({"target":{"id":"gender","value":"Prefer to self-describe (please specify)"}})} id="genderDescribe" name="genderCheck" label="Prefer to self-describe (please specify)" />    
                        </div>
                        <div className={`form-group ${!specifyGender ? "hide" : ""}`}>                            
                            <FloatingLabel label="Please write your gender">
                                <Form.Control className="border-0 rounded-0 border-bottom" type="text" id="genderSpecify" placeholder="Please write your gender" onChange={(gender)=>updateSignupDataState(gender) } />
                            </FloatingLabel>
                        </div>
                    </div>
                </div>
                <div className="position-fixed bottom-0 w-100 bg-secondary p-3 d-flex justify-content-between align-items-center">
                    <button type="button" onClick={()=>setApplicationStep(3)} className="btn btn-sm btn-outline-primary">Back</button>
                    <button type="button" onClick={()=>setApplicationStep(5)} className="btn btn-sm btn-primary">Continue</button>
                </div>                
            </Container>

            <Container className={`influencerStep ${applicationStep != 5 ? "hide" : ""}`}>
                <div className="px-3 py-4 influencerStepBody">
                    <div className='w-100 text-center position-relative pb-2 mb-4'>
                        <img src={theRoomIcon} />
                    </div>
                    <ProgressBar className="progressInfluencer" animated now={40} />
                    <div className="text-center mt-4 h5 fw-bold">What is your date of birth?</div>
                    <div className="px-3 mt-4">
                        <FloatingLabel label="Date of birth">
                            <Form.Control className="border-0 rounded-0 border-bottom" type="date" id="dob" placeholder="Date of birth" onChange={ updateSignupDataState } />
                        </FloatingLabel>
                        <div className="px-3 text-center mt-4 small">
                            <p>You must be over 18 to join The Influence Room as we work with some brands who offer age restricted campaigns.</p>
                        </div>
                    </div>
                </div>
                <div className="position-fixed bottom-0 w-100 bg-secondary p-3 d-flex justify-content-between align-items-center">
                    <button type="button" onClick={()=>setApplicationStep(4)} className="btn btn-sm btn-outline-primary">Back</button>
                    <button type="button" onClick={()=>setApplicationStep(6)} className="btn btn-sm btn-primary">Continue</button>
                </div>                
            </Container>

            <Container className={`influencerStep ${applicationStep != 6 ? "hide" : ""}`}>
                <div className="px-3 py-4 influencerStepBody">
                    <div className='w-100 text-center position-relative pb-2 mb-4'>
                        <img src={theRoomIcon} />
                    </div>
                    <ProgressBar className="progressInfluencer" animated now={50} />
                    <div className="text-center mt-4 h5 fw-bold">Where are you located?</div>
                    <div className="px-3 mt-4">
                        <Select
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            defaultValue={[]}
                            options={countries} 
                            id="country" 
                            onChange={ (choice) => updateSignupDataState({"target":{"id":"country","value":choice.value}}) } 
                        />
                        <div className="px-3 text-center mt-4 small">
                            <p>Please let us know what country you are currently based in. This will not affect your ability to bid on campaigns outside your country but our brands like to know where their influencers come from!</p>
                        </div>
                    </div>
                </div>
                <div className="position-fixed bottom-0 w-100 bg-secondary p-3 d-flex justify-content-between align-items-center">
                    <button type="button" onClick={()=>setApplicationStep(5)} className="btn btn-sm btn-outline-primary">Back</button>
                    <button type="button" onClick={()=>setApplicationStep(7)} className="btn btn-sm btn-primary">Continue</button>
                </div>                
            </Container>

            <Container className={`influencerStep ${applicationStep != 7 ? "hide" : ""}`}>
                <div className="px-3 py-4 influencerStepBody">
                    <div className='w-100 text-center position-relative pb-2 mb-4'>
                        <img src={theRoomIcon} />
                    </div>
                    <ProgressBar className="progressInfluencer" animated now={60} />
                    <div className="text-center mt-4 h5 fw-bold">Primary Content Category</div>
                    <div className="mt-3">
                        <div className="text-muted text-center px-3 small">Please select your main focus for content</div>
                        <div className="mt-4">
                            <ToggleButtonGroupPrimaryCategories />
                        </div>
                    </div>
                </div>
                <div className="position-fixed bottom-0 w-100 bg-secondary p-3 d-flex justify-content-between align-items-center">
                    <button type="button" onClick={()=>setApplicationStep(6)} className="btn btn-sm btn-outline-primary">Back</button>
                    <button type="button" onClick={()=>setApplicationStep(8)} className="btn btn-sm btn-primary">Continue</button>
                </div>                
            </Container>

            <Container className={`influencerStep ${applicationStep != 8 ? "hide" : ""}`}>
                <div className="px-3 py-4 influencerStepBody">
                    <div className='w-100 text-center position-relative pb-2 mb-4'>
                        <img src={theRoomIcon} />
                    </div>
                    <ProgressBar className="progressInfluencer" animated now={70} />
                    <div className="text-center mt-4 h5 fw-bold">Interests</div>
                    <div className="mt-3">
                        <div className="text-muted text-center px-3 small">We'll use this information to tailor your experience</div>
                        <div className="mt-4">
                            <ToggleButtonGroupOtherCategories />
                        </div>
                    </div>
                </div>
                <div className="position-fixed bottom-0 w-100 bg-secondary p-3 d-flex justify-content-between align-items-center">
                    <button type="button" onClick={()=>setApplicationStep(7)} className="btn btn-sm btn-outline-primary">Back</button>
                    <button type="button" onClick={()=>setApplicationStep(9)} className="btn btn-sm btn-primary">Continue</button>
                </div>                
            </Container>

            <Container className={`influencerStep ${applicationStep != 9 ? "hide" : ""}`}>
                <div className="px-3 py-4 influencerStepBody">
                    <div className='w-100 text-center position-relative pb-2 mb-4'>
                        <img src={theRoomIcon} />
                    </div>
                    <ProgressBar className="progressInfluencer" animated now={80} />
                    <div className="text-center mt-4 h5 fw-bold">Add social media accounts</div>
                    <div className="px-3 mt-3">
                        <div className="text-muted text-center small">Please add at least one social media account</div>
                        <FloatingLabel label="Instagram" className="position-relative mt-3">
                            <Form.Control className="border-0 rounded-0 border-bottom" id="social_instagram" onChange={ updateSignupDataState } type="text" placeholder="Instagram" />
                        </FloatingLabel>
                        <FloatingLabel label="TikTok" className="position-relative mt-3">
                            <Form.Control className="border-0 rounded-0 border-bottom" id="social_tiktok" onChange={ updateSignupDataState } type="text" placeholder="TikTok" />
                        </FloatingLabel>
                        <FloatingLabel label="Youtube" className="position-relative mt-3">
                            <Form.Control className="border-0 rounded-0 border-bottom" id="social_youtube" onChange={ updateSignupDataState } type="text" placeholder="Youtube" />
                        </FloatingLabel>
                        <FloatingLabel label="Facebook" className="position-relative mt-3">
                            <Form.Control className="border-0 rounded-0 border-bottom" id="social_facebook" onChange={ updateSignupDataState } type="text" placeholder="Facebook" />
                        </FloatingLabel>
                        <FloatingLabel label="Twitter" className="position-relative mt-3">
                            <Form.Control className="border-0 rounded-0 border-bottom" id="social_twitter" onChange={ updateSignupDataState } type="text" placeholder="Twitter" />
                        </FloatingLabel>
                        <div className="mt-4 mb-4 small text-center text-muted">
                            <p>So that The Influence Room and our Brands can review the content you create, we require at least one social media account to be added to your profile.</p>
                        </div>
                    </div>
                </div>
                <div className="position-fixed bottom-0 w-100 bg-secondary p-3 d-flex justify-content-between align-items-center">
                    <button type="button" onClick={()=>setApplicationStep(8)} className="btn btn-sm btn-outline-primary">Back</button>
                    <button type="button" onClick={()=>setApplicationStep(10)} className="btn btn-sm btn-primary">Continue</button>
                </div>                
            </Container>

            <Container className={`influencerStep ${applicationStep != 10 ? "hide" : ""}`}>
                <div className="px-3 py-4 influencerStepBody">
                    <div className='w-100 text-center position-relative pb-2 mb-4'>
                        <img src={theRoomIcon} />
                    </div>
                    <ProgressBar className="progressInfluencer" animated now={90} />
                    <div className="text-center mt-4 h5 fw-bold">Were you referred by someone?</div>
                    <div className="px-3 mt-4">
                        <div className="border rounded p-2 mb-3">
                            <Form.Check className="ms-1 pt-1" type="radio" checked={isReferral} onChange={()=>setIsReferral(true)} id="referralYes" name="referral" label="Yes" /> 
                        </div>   
                        <div className="border rounded p-2 mb-3">
                            <Form.Check className="ms-1 pt-1" type="radio" checked={!isReferral} onChange={()=>setIsReferral(false)} id="referralNo" name="referral" label="No" />    
                        </div>
                        <div className={`form-group ${!isReferral ? "hide" : ""}`}>                            
                            <FloatingLabel label="Their referral code">
                                <Form.Control className="border-0 rounded-0 border-bottom" type="text" id="referral" placeholder="Please add their referral code" onChange={(referral)=>updateSignupDataState(referral) } />
                            </FloatingLabel>
                        </div>
                    </div>
                </div>
                <div className="position-fixed bottom-0 w-100 bg-secondary p-3 d-flex justify-content-between align-items-center">
                    <button type="button" onClick={()=>setApplicationStep(9)} className="btn btn-sm btn-outline-primary">Back</button>
                    <button type="button" onClick={()=>setApplicationStep(11)} className="btn btn-sm btn-primary">Continue</button>
                </div>                
            </Container>

            <Container className={`influencerStep ${applicationStep != 11 ? "hide" : ""}`}>
                <div className="px-3 py-4 influencerStepBody">
                    <div className='w-100 text-center position-relative pb-2 mb-4'>
                        <img src={theRoomIcon} />
                    </div>
                    <ProgressBar className="progressInfluencer" animated now={100} />
                    <div className="text-center mt-4 h5 fw-bold">Ready to Submit?</div>
                    <div className="px-3 mt-4">
                        <div className="mt-4 mb-4 small text-center text-muted">
                            <p>We're very excited to have you apply to join us, however we first just need to make sure that you agree to our T&C's and understand our privacy policy.</p>
                        </div>
                        <div className="p-2 mb-3">
                            <label><input type="checkbox" className="form-check-input" id="terms" onChange={ updateSignupDataState } /> &nbsp;I have read and agree to the <a href="https://www.theinfluenceroom.com/terms-of-use/" target='_blank'>Terms and Conditions</a> and <a href="https://www.theinfluenceroom.com/privacy-policy/" target='_blank'>Privacy Policy</a></label>
                        </div>

                        <ReCAPTCHA
                            sitekey={parameters.recaptcha}
                            onChange={(token) => {setCaptchaToken(token)}}
                        />

                    </div>
                    {HandleAlert.state &&
                        <Alert variant="warning" onClose={() => setHandleAlert({"state": false, "title": "", "message": ""})} className="handleAlert start-50 top-0 translate-middle-x w-75 mt-4" transition>
                            <Alert.Heading>{HandleAlert.title}</Alert.Heading>
                            {HandleAlert.message}
                        </Alert>
                    }  
                </div>
                <div className="position-fixed bottom-0 w-100 bg-secondary p-3 d-flex justify-content-between align-items-center">
                    <button type="button" onClick={()=>setApplicationStep(10)} className="btn btn-sm btn-outline-primary">Back</button>
                    <button type="button" onClick={submit} disabled={!captchaToken} className="btn btn-sm btn-primary">Submit Application</button>
                </div>                
            </Container>

        </div>
      );
    }